<template>
    <auth-form :inputs="inputs" :msgtext="msg" @toggle-password="togglePasswordVisibility"/>
    <Loader  :show-loader="showLoader"/>
</template>

<script>

import AuthForm from "@/components/AuthForm";
import Functions from "@/assets/js/functions";
import Loader from "@/components/Loader.vue";

export default {
    name: 'App',
    components: {
        AuthForm,
        Loader
    },
    data() {
        return {
            inputs: [
                {name: "login", type: "text", text: "", placeholder: "Логин", onenter: false, action: false},
                {
                    name: "password",
                    type: "password",
                    text: "",
                    placeholder: "Пароль",
                    onenter: this.login,
                    action: false
                },
                {name: "button", type: "button", text: "Войти", placeholder: "", onenter: false, action: this.login}
            ],
            showPassword: false,
            showLoader: false,
            msg: ""
        }
    },
    methods: {
        login(item) {
            let s = this;
            s.showLoader = true;
            Functions.sync("authsystem", {
                login: this.inputs[0].text,
                password: this.inputs[1].text,
                api_key: "e9daa2e6-510c-47e6-8537-142293e9a9f9"
            }, function (json) {
                if (json.type == 'success') {
                    document.cookie = "token=" + json.data.token + "; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT"
                    document.location = 'http://cwsys.ru';//ПОМЕНЯТЬ НА ПРОСТО ПАПКУ НАЗАД
                } else {
                    console.log(json);
                    s.msg = json.msg;
                    s.showLoader = false;
                }
            });
        },
        togglePasswordVisibility(showPassword) {
            this.showPassword = showPassword;
        },
    }
}
</script>

<style>
@font-face {
    font-family: 'Inter';
    src: url("./assets/fonts/Inter-Regular.ttf") format('truetype');
}

body {
    font-family: Inter;
}

#app {
    background: url("@/assets/background-1922.jpg") center center no-repeat; /*#2B2A31;*/
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    font-family: Inter;
}

body {
    background: #2B2A31;
}

*,
html * /* override x.xhtml.ru style */
{
    scrollbar-width: thin;
    scrollbar-color: blue orange;
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
    background: none;
}

*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
    border: 3px solid #767676;
    background: #767676;
    border-radius: 4px;
    width: 4px;
}

@media screen and (max-width: 850px) {
    #app {
    //background: url("@/assets/background-744.jpg") center center no-repeat;
    }

}
</style>
