<template>
    <div class="input-container">
        <input :type="getType()" @click="getAction" v-on:keyup.enter="getOnEnter" v-model="value" :value="getText()" :placeholder="getPHolder()">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: "afinputBox",
  props: {
    params: {
      type: Object,
      required: true
    },
      showpas: {
        type: Boolean,
        required: true
    }
  },
  data(){
    return {
      value: this.params.type!='button'?"":this.params.text
    }
  },
  watch:{
    value:{
      handler(n){
        if(this.params.type != 'button') this.params.text = n;
      }
    }
  },
  methods:{
    getType(){
        if(this.showpas && this.params.type == 'password') {
            return 'text'
        }
      if(this.params.type != undefined ){
        return this.params.type;
      }
    },
    getText(){
      if(this.params.text != undefined){
        return this.params.text;
      }
      return ''
    },
    getAction(){
      if(this.params.action) {
        return this.params.action(this.params);
      }
    },
    getOnEnter(){
      if(this.params.onenter) {
        return this.params.onenter(this.params);
      }
    },
    getPHolder(){
      if(this.params.placeholder) {
        return this.params.placeholder;
      }
      return "";
    }
  }
}
</script>

<style scoped>
  .input-container:nth-child(2) {
      position: relative;
  }
  input{
    border: 0.5px solid #6C6C6C;
    border-radius: 7px;
    outline: none;
    background: none;
    width: 100%;
    box-sizing: border-box;
    height: 26px;
    margin-top: 20px;
    color: #FFF;
    text-align: left;
    padding-left: 2%;
    font-size: 12px;
  }
  input[type=button]{
    border: none;
    background: #FFCA05;
    color: #000;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
  }
  input:hover{
    cursor: pointer;
  }
  input:focus {
    border-color: #FFF;
  }
</style>