<template>
  <div class="bgdivout">
    <div class="bgdivin">
      <div class="logo"></div>
        <template v-for="inp in inputs" :key="inp">
            <afinput-box :params="inp" @action="inp.action" :showpas="showPassword">
                <img :src="showPassword ? require('@/assets/eye.svg') : require('@/assets/eye-off.svg')"
                     @click="togglePasswordVisibility" v-if="inp.type === 'password'" class="hidepas-img"/>
            </afinput-box>
        </template>
    </div>
    <div class="msgBox" :class="{show :msgtext!='', hide :msgtext==''}">
      {{ msgtext }}
    </div>
  </div>
</template>

<script>

import AfinputBox from "@/components/afinputBox";

export default {
  name: "AuthForm",
  props:{
    inputs:{
      type: Object
    },
    msgtext:{
      type: String
    }
  },
  components: {AfinputBox},
  data() {
      return {
          showPassword: false,
      }
  },
  methods:{
      togglePasswordVisibility() {
          this.showPassword = !this.showPassword;
          this.$emit('toggle-password', this.showPassword);
      },
  }
}
</script>

<style scoped>
  .show{
    display: block;
  }
  .hide{
    display: none;
  }
  .msgBox{
    position: relative;
    left: -44%;
    top:-68%;
    width: 424px;
    height: auto;
    border-radius: 8px;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    color: #FF4141;
    transition: 0.5s ease-in;
  }
  .logo{
    width: 80%;
    height: 38px;
    background: url("@/assets/logo.svg") center center no-repeat;
    margin-left: 10%;
  }
  .bgdivout {
    position: absolute;
    width: 424px;
    height: 256px;
    left: 50%;
    top: 50%;
  }
  .bgdivin{
    box-sizing: border-box;
    position: relative;
    width: 474px;
    height: auto;
    padding: 40px 18px;
    left: -50%;
    top: -50%;
    border-radius: 7px;
    border: 1px solid #FFCA05;
    background: rgba(43, 42, 49, 0.70);
    box-shadow: 0 0 16px 0 rgba(255, 202, 5, 0.50);
    backdrop-filter: blur(5px);
  }
  .hidepas-img {
    position: absolute;
    cursor: pointer;
    right: 27px;
    top: 58.5%;
  }
  @media screen and (max-width: 850px) {
      .bgdivout {
          margin: 0 auto;
          position: fixed;
          right: auto;
          width: 280px;
      }
      .bgdivin {
          width: 280px;
      }
      .msgBox {
          left: -73%;
      }
  }
</style>