<template>
    <div class="loader-container" v-if="showLoader">
        <img src="../assets/loader2.gif" alt="loader"/>
    </div>
</template>

<script>
export default {
    name: "Loader",
    props: {
        showLoader: {
            type: Boolean,
            required: true
        }
    },
    watch: {
        value: {
            handler(n) {
                if (this.params.type != 'button') this.params.text = n;
            }
        }
    }
}
</script>

<style scoped>
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1000;
}


</style>