const backend_url = "http://api.cwsys.ru/";

export default class Functions {


    static ajax(url, params = null, func = null) {
        let xhr = new XMLHttpRequest();
        xhr.open("post", backend_url + url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    if (func !== null) {
                        func(JSON.parse(xhr.response));
                    }
                }
            }
        };
        let fd = new FormData();
        if (params !== null) {
            Object.entries(params).forEach((element) => {
                fd.append(element[0], element[1]);
            });
        }

        xhr.send(fd);
    }

    static sync(url, params = null, func = null) {
        let xhr = new XMLHttpRequest();
        xhr.open("post", backend_url + url, false);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    if (func !== null) {
                        func(JSON.parse(xhr.response));
                    }
                }
            }
        };
        let fd = new FormData();
        if (params !== null) {
            Object.entries(params).forEach((element) => {
                fd.append(element[0], element[1]);
            });
        }

        xhr.send(fd);
    }


}

